(function () {
  let angular = window.angular;

  Router.$inject = ['$stateProvider'];

  angular.module('app').config(Router);

  function Router($stateProvider) {
    $stateProvider.state('public.storeOpeningForm', {
      url: '/store-opening?token',
      component: 'storeOpeningForm',
      resolve: {
        module: [
          '$ocLazyLoad',
          ($ocLazyLoad) =>
            $ocLazyLoad.load([
              window.assetsPath + '/js/app/store-opening-form.module.min.js',
              window.assetsPath + '/js/vendors/cron-schedule.iife.min.js',
            ]),
        ],
      },
    });
  }
})();
