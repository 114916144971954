(function () {
  'use strict';

  let angular = window.angular;
  EventRouter.$inject = ['$stateProvider'];

  angular.module('app').config(EventRouter);

  function EventRouter($stateProvider) {
    $stateProvider
      .state('member.project.events', {
        url: '/events',
        redirectTo: 'member.project.events.dashboard.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.event.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/app/dashboard-common.module.min.js',
                window.assetsPath + '/js/app/event-management.module.min.js',
                window.assetsPath + `/css/event-management-${style}.css`,
              ]);
            },
          ],
          users: [
            'OrganizationService',
            'user',
            function (OrganizationService, user) {
              return OrganizationService.getUsers({ id: user.customerId });
            },
          ],
          adminUsers: [
            '$q',
            function ($q) {
              return $q.resolve([]);
            },
          ],
        },
      })
      .state('member.project.events.report', {
        url: '/report?from&to',
        component: 'xompassEventReport',
        authenticated: true,
        params: {
          from: { type: 'string', dynamic: true, squash: true },
          to: { type: 'string', dynamic: true, squash: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventReport" | translate}}' },
        resolve: {
          assets: [
            'Customer',
            '$rootScope',
            'AppUtils',
            (Customer, $root, utils) => {
              return Customer.prototype$__get__assets({
                id: $root.customerId,
                filter: {
                  where: { projectId: $root.projectId },
                  fields: ['id', 'name', 'type', 'customerId', 'assetId'],
                  include: [
                    {
                      relation: 'eventGroups',
                      scope: { fields: ['name', 'color', 'id', 'priority'] },
                    },
                    {
                      relation: 'eventTriggers',
                      scope: {
                        fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
                      },
                    },
                  ],
                },
              }).$promise.then((result) => {
                prepareAssets(result, utils);
                return result;
              });
            },
          ],
          users: [
            'Customer',
            '$rootScope',
            'AppUtils',
            function (Customer, $root, utils) {
              return Customer.managers({
                id: $root.customerId,
                filter: { fields: ['id', 'name', 'surname', 'username'] },
              }).$promise;
            },
          ],
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
                window.assetsPath + '/js/vendors/excel-vendors.min.js',
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventReport');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassEventReport' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.project.events.history', {
        url: '/history?tab',
        component: 'eventHistoryHome',
        redirectTo: 'member.project.events.history.show',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventHistory" | translate}}' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventHistory');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.invalidTool',
                  params: { tool: 'xompassEventHistory' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.project.events.history.show', {
        url: '/show',
        component: 'xompassEventHistory',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.tool.__tools.EventHistory" | translate}}',
        },
      })
      .state('member.project.events.history.importants', {
        url: '/importants',
        component: 'eventHistoryImportant',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.tool.__tools.ImportantEvents" | translate}}',
        },
      })
      .state('member.project.events.viewer', {
        url: '/viewer?assetId&id&eventTriggerId',
        component: 'eventViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.viewer" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          asset: [
            'AssetService',
            'AppUtils',
            '$transition$',
            function (AssetService, utils, $transition) {
              const assetId = $transition.params().assetId;
              return AssetService.findById(assetId, {
                fields: [
                  'id',
                  'name',
                  'type',
                  'icon',
                  'path',
                  'customerId',
                  '_config',
                  'assetId',
                  'currentStateId',
                ],
                include: [
                  {
                    relation: 'eventGroups',
                    scope: { fields: ['name', 'color', 'id', 'priority'] },
                  },
                  {
                    relation: 'eventTriggers',
                    scope: {
                      fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
                    },
                  },
                ],
              })
                .then((asset) => {
                  asset.eventTriggers = utils.arrayToObject(asset.eventTriggers);
                  return asset;
                })
                .catch((e) => {
                  console.log(e);
                });
            },
          ],
          event: [
            '$transition$',
            function ($transition) {
              const params = $transition.params();
              return {
                id: params.id,
                eventTriggerId: params.eventTriggerId,
              };
            },
          ],
        },
      })
      .state('member.project.events.dashboard', {
        url: '/dashboard?date',
        redirectTo: 'member.project.events.dashboard.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.dashboard" | translate}}',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find(
                (tool) =>
                  tool.module === 'xompassEventDashboard' ||
                  tool.module === 'xompassEventDashboardDemo'
              );
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassEventDashboard' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.project.events.dashboard.home', {
        url: '/',
        component: 'eventDashboardHome',
        authenticated: true,
        resolve: {
          dashboards: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'AUTO' } };
              return EntityService.find('EventDashboard', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.project.events.dashboard.new', {
        url: '/new',
        component: 'eventDashboardEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"buttons.new" | translate}}',
        },
      })
      .state('member.project.events.dashboard.show', {
        url: '/:dashboardId?false_positive',
        component: 'eventDashboardShow',
        redirectTo: 'member.project.events.dashboard.show.management',
        authenticated: true,
        params: {
          false_positive: { type: 'string' },
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          dashboard: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadEventDashboard(EntityService, $transition$.params(), utils, 'AUTO');
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.dashboard.name}}',
        },
      })
      .state('member.project.events.dashboard.show.management', {
        url: '/management',
        component: 'eventDashboardManagement',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.management" | translate}}',
        },
      })
      .state('member.project.events.dashboard.show.situational', {
        url: '/situational',
        component: 'eventDashboardSituational',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.map" | translate}}',
        },
      })
      .state('member.project.events.dashboard.show.editor', {
        url: '/editor',
        component: 'eventDashboardEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.editor" | translate}}',
        },
      })
      .state('member.project.events.dashboard.show.history', {
        url: '/history?tab&false_positive',
        component: 'xompassEventHistory',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
          false_positive: { type: 'string' },
        },
        ncyBreadcrumb: { label: '{{"views.tools.EventDashboard.breadcrumb.history" | translate}}' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      })

      .state('member.project.events.store-anomalies', {
        url: '/store-anomalies?date',
        redirectTo: 'member.project.events.store-anomalies.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.dashboard" | translate}}',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventDashboard');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassEventDashboard' },
                });
              }
            },
          ],
          hours: () => 24,
          date: () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            date.setHours(0, 0, 0, 0);
            return date;
          },
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.project.events.store-anomalies.report', {
        url: '/report?from&to',
        component: 'storeAnomaliesReport',
        authenticated: true,
        params: {
          from: { type: 'string', dynamic: true, squash: true },
          to: { type: 'string', dynamic: true, squash: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventReport" | translate}}' },
        resolve: {
          assets: [
            'Customer',
            '$rootScope',
            'AppUtils',
            (Customer, $root, utils) => {
              return Customer.prototype$__get__assets({
                id: $root.customerId,
                filter: {
                  where: { projectId: $root.projectId, type: 'Store' },
                  fields: ['id', 'name', 'type', 'customerId', 'assetId'],
                  include: [
                    {
                      relation: 'eventGroups',
                      scope: { fields: ['name', 'color', 'id', 'priority'] },
                    },
                    {
                      relation: 'eventTriggers',
                      scope: {
                        fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
                      },
                    },
                  ],
                },
              }).$promise.then((result) => {
                prepareAssets(result, utils);
                return result;
              });
            },
          ],
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
                window.assetsPath + '/js/vendors/excel-vendors.min.js',
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassStoreAnomaliesReport');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassStoreAnomaliesReport' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.project.events.store-anomalies.home', {
        url: '/',
        component: 'eventDashboardHome',
        authenticated: true,
        resolve: {
          dashboardType: () => 'STORE_ANOMALIES',
          dashboards: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'STORE_ANOMALIES' } };
              return EntityService.find('EventDashboard', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.project.events.store-anomalies.show', {
        url: '/:dashboardId?false_positive',
        component: 'storeAnomaliesShow',
        redirectTo: 'member.project.events.store-anomalies.show.management',
        authenticated: true,
        params: {
          false_positive: { type: 'string' },
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          dashboard: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadEventDashboard(
                EntityService,
                $transition$.params(),
                utils,
                'STORE_ANOMALIES'
              );
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.dashboard.name}}',
        },
      })
      .state('member.project.events.store-anomalies.show.management', {
        url: '/management',
        component: 'storeAnomaliesDashboardManagement',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.management" | translate}}',
        },
      })
      .state('member.project.events.store-anomalies.show.editor', {
        url: '/editor',
        component: 'storeAnomaliesEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.editor" | translate}}',
        },
      })
      .state('member.project.events.store-anomalies.show.history', {
        url: '/history?tab&false_positive',
        component: 'xompassEventHistory',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
          false_positive: { type: 'string' },
        },
        ncyBreadcrumb: { label: '{{"views.tools.EventDashboard.breadcrumb.history" | translate}}' },
      });

    function loadEventDashboard(EntityService, params, utils, type) {
      const redirect = {
        AUTO: 'member.project.events.dashboard.home',
        //'GENERIC': 'member.project.gis.home',
        //'SAFE_CITY': 'member.project.fiber.home'
      };

      const options = angular.copy(params);
      options.entityId = options.dashboardId;

      const assetInclude = [
        {
          relation: 'eventGroups',
          scope: { fields: ['name', 'color', 'id', 'priority'] },
        },
        {
          relation: 'eventTriggers',
          scope: {
            fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
          },
        },
      ];
      if (type === 'STORE_ANOMALIES') {
        assetInclude.push({
          relation: 'staffs',
          scope: { fields: ['type', 'name', 'referenceId'] },
        });
      }

      options.filter = {
        where: { type: type },
        include: [
          'managers',
          {
            relation: 'assets',
            scope: {
              fields: [
                'id',
                'name',
                'type',
                'icon',
                'path',
                'customerId',
                '_config',
                'assetId',
                'currentStateId',
                'uri',
              ],
              include: assetInclude,
            },
          },
        ],
      };

      return EntityService.findById('EventDashboard', options)
        .then((dashboard) => {
          prepareAssets(dashboard.assets, utils);
          return dashboard;
        })
        .catch((err) => {
          console.log(err);
          throw utils.getHTTPError(err, {
            redirectTo: redirect[type],
            params: { customerId: params.customerId, projectId: params.projectId },
          });
        });
    }

    function prepareAssets(assets, utils) {
      assets.forEach((asset) => {
        asset.eventTriggers = utils.arrayToObject(asset.eventTriggers || [], 'id');
        asset.sensors = asset.sensors || [];
        asset.sensors.forEach((sensor) => {
          sensor.states = sensor.states || [];
          sensor.currentState = sensor.states.find((state) => {
            return state.assetStateId === asset.currentStateId;
          });
        });
      });
    }
  }
})();
