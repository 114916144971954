(function () {
  'use strict';

  let angular = window.angular;

  MonitoringRouter.$inject = ['$stateProvider'];

  angular.module('app').config(MonitoringRouter);

  function MonitoringRouter($stateProvider) {
    $stateProvider
      .state('member.project.monitoring', {
        url: '/monitoring',
        abstract: true,
        template: '<ui-view></ui-view>',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const theme = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${theme}.min.js`,
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          skip: true,
        },
      })
      .state('member.project.monitoring.realTime', {
        url: '/real-time/:assetId?columns',
        component: 'monitoringRealTime',
        authenticated: true,
        params: {
          assetId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: 'Real Time',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassRealTime');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassRealtime' },
                });
              }
            },
          ],
        },
      })
      .state('member.project.monitoring.timeSeries', {
        url: '/time-series',
        component: 'monitoringTimeSeries',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Time Series',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassTimeSeries');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassTimeSeries' },
                });
              }
            },
          ],
        },
      });
  }
})();
