(function () {
  'use strict';

  let angular = window.angular;

  Router.$inject = ['$stateProvider'];

  angular.module('app').config(Router);

  function Router($stateProvider) {
    $stateProvider
      .state('member.project.biPanel', {
        url: '/bi-panel',
        redirectTo: 'member.project.biPanel.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Bi Panels',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassBIPanels');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassBIPanels' },
                });
              }
            },
          ],
          module: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/bi-panel.module.min.js']);
            },
          ],
        },
      })
      .state('member.project.biPanel.home', {
        url: '/',
        component: 'biPanelHome',
        authenticated: true,
        resolve: {
          reports: [
            '$rootScope',
            'Customer',
            function ($root, Customer) {
              return Customer.prototype$__get__projects__biPanels({
                id: $root.customerId,
                nk: $root.projectId,
              }).$promise;
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.project.biPanel.dashboard', {
        url: '/:reportId',
        redirectTo: 'member.project.biPanel.dashboard.viewer',
        component: 'biPanelDashboard',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const theme = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${theme}.min.js`,
              ]);
            },
          ],
          virtualGroups: [
            '$rootScope',
            'Customer',
            ($root, Customer) => {
              return Customer.prototype$__get__projects__virtualGroups({
                id: $root.customerId,
                nk: $root.projectId,
              }).$promise;
            },
          ],
          virtualExpressions: [
            '$rootScope',
            'VirtualExpressionService',
            function ($root, VirtualExpressionService) {
              return VirtualExpressionService.find($root.customerId, $root.projectId, {
                fields: ['id', 'name', 'description', 'expression'],
              });
            },
          ],
          report: [
            'Customer',
            '$rootScope',
            '$transition$',
            'AppUtils',
            function (Customer, $root, $transition$, utils) {
              let params = $transition$.params();
              if (params.reportId) {
                return loadBIPanel(Customer, $root.customerId, $root.projectId, params.reportId)
                  .then((result) => {
                    if (!result) {
                      throw utils.getHTTPError(new Error('BIPanel not found'), {
                        redirectTo: 'member.project.biPanel.home',
                        params: { customerId: $root.customerId, projectId: $root.projectId },
                      });
                    }
                    return result;
                  })
                  .catch((err) => {
                    throw utils.getHTTPError(err, {
                      redirectTo: 'member.project.biPanel.home',
                      params: { customerId: $root.customerId, projectId: $root.projectId },
                    });
                  });
              }
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.report.name}}',
        },
      })

      .state('member.project.biPanel.dashboard.viewer', {
        url: '/viewer',
        component: 'biPanelViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      })
      .state('member.project.biPanel.dashboard.editor', {
        url: '/editor',
        component: 'biPanelEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      });

    function loadBIPanel(Customer, customerId, projectId, reportId) {
      return Customer.prototype$__findById__projects__biPanels({
        id: customerId,
        nk: projectId,
        fk: reportId,
        filter: {
          include: ['series', 'vertices'],
        },
      }).$promise.catch((err) => {
        console.error(err);
        return null;
      });
    }
  }
})();
