;(function () {
  'use strict';

  let angular = window.angular;

  AssetTemplateService.$inject = ['Customer', 'AssetTemplate', '$rootScope', '$q', '$state', 'iconService', 'AppUtils', 'assetUtilsService'];

  angular
    .module('appServices')
    .service('AssetTemplateService', AssetTemplateService);

  function AssetTemplateService (Customer, AssetTemplate, $root, $q, $state, iconService, appUtils, assetUtilsService) {
    return {
      findById: findById,
      find: find,
      getAssetTemplateTree: getAssetTemplateTree,
      getAssetTemplateTreeNode: assetUtilsService.getAssetTreeNode,
      getTemplatePictureUrl: getTemplatePictureUrl
    };

    function findById (assetTemplateId, filter) {
      filter = filter || {};
      filter.where = { id: assetTemplateId };

      let defer = $q.defer();
      Customer
        .assetTemplates({
          id: $root.customerId,
          filter: filter
        })
        .$promise
        .then(assetTemplates => {
          if (!assetTemplates || !assetTemplates.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';

            throw err;
          }
          defer.resolve(assetTemplates[0]);
        })
        .catch(err => {
          defer.reject(err);
        });

      return defer.promise;
    }

    function find (filter) {
      filter = filter || {};

      let params = $state.params;
      let defer = $q.defer();
      Customer
        .prototype$__get__assetTemplates({
          id: $root.customerId,
          nk: params.projectId,
          filter: filter
        })
        .$promise
        .then(assetTemplates => {
          defer.resolve(assetTemplates);
        })
        .catch(err => {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getAssetTemplateTree (assetTemplates) {
      let defer = $q.defer();
      iconService
        .getAssetIcons()
        .then(assetIcons => {
          defer.resolve({
            tree: generateTree(assetTemplates, assetIcons),
            assetTemplates: appUtils.arrayToObject(assetTemplates)
          });
        })
        .catch(function (err) {
          defer.reject(err);
        });

      return defer.promise;
    }

    function generateTree (assetTemplates, icons) {

      let tree = [];

      assetTemplates.forEach(function (assetTemplate) {
        tree.push(getAssetTemplateTreeNode(assetTemplate, icons));
      });
      return tree;
    }

    function getAssetTemplateTreeNode (assetTemplate, assetIcons) {
      return {
        id: assetTemplate.id,
        text: assetTemplate.name,
        icon: assetIcons.base + assetIcons.icons[assetTemplate.icon || 'generic'].black,
        parent: assetTemplate.assetTemplateId || '#'
      };
    }

    function getAssetTemplateTree (assetTemplates) {
      return assetUtilsService.getAssetTree(assetTemplates, 'AssetTemplate');
    }

    function getTemplatePictureUrl (templateId, token) {
      return assetUtilsService.getImageUrl(templateId, 'picture', 'AssetTemplate');
    }
  }
})();
