(function () {
  'use strict';

  let angular = window.angular;

  forensicRetailRouter.$inject = ['$stateProvider'];

  angular.module('app').config(forensicRetailRouter);

  function forensicRetailRouter($stateProvider) {
    $stateProvider
      .state('member.project.forensicRetail', {
        url: '/forensic-retail',
        component: 'forensicRetailViewer',
        authenticated: true,
        //redirectTo: 'member.project.forensicRetail.viewer',
        ncyBreadcrumb: {
          label: '{{"modules.forensic-retail.breadcrumb" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + `/css/forensic-retail.style-${style}.css`,
                window.assetsPath + '/js/app/forensic-retail.module.min.js',
              ]);
            },
          ],
        },
      })
      .state('member.project.forensicRetail.viewer', {
        url: '/viewer',
        component: 'forensicRetailViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      });
  }
})();
