(() => {
  const angular = window.angular;

  angular.module('app').config(ReportRouter);

  ReportRouter.$inject = ['$stateProvider'];

  function ReportRouter($stateProvider) {
    $stateProvider
      .state('member.project.forensicLicensePlates', {
        url: '/forensic-license-plates',
        component: 'forensicLicensePlates',
        redirectTo: 'member.project.forensicLicensePlates.search',
        authenticated: true,
        resolve: {
          module: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/css/forensic-license-plates.style-${style}.css`,
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + '/js/app/forensic-analysis.module.min.js',
                window.assetsPath + '/js/app/forensic-license-plate.module.min.js',
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"modules.forensic-license-plate.breadcrumb" | translate}}',
        },
      })
      .state('member.project.forensicLicensePlates.search', {
        url: '/search',
        component: 'forensicLicensePlatesSearchV2',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.forensic-license-plate.breadcrumbSearch" | translate}}',
        },
      })
      .state('member.project.forensicLicensePlates.blacklist', {
        url: '/blacklist',
        component: 'forensicLicensePlatesBlacklist',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.forensic-license-plate.breadcrumbBlacklist" | translate}}',
        },
      })
      .state('member.project.forensicLicensePlates.blacklistDetections', {
        url: '/blacklist-detections',
        component: 'forensicLicensePlatesBlacklistDetections',
        authenticated: true,
        resolve: {
          module: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/app/event-management.module.min.js',
                window.assetsPath + `/css/event-management-${style}.css`,
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"modules.forensic-license-plate.breadcrumbBlacklistDetections" | translate}}',
        },
      });
  }
})();
