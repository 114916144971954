(function () {
  'use strict';
  const angular = window.angular;

  NavbarService.$inject = ['$rootScope', 'Project', 'socketIOService'];

  angular.module('appServices').service('navbarService', NavbarService);

  function NavbarService($root, Project, socketIOService) {
    let navbarData = {};
    let prevProjectId = null;

    return {
      data: navbarData,
      setProjects: setProjects,
      setProject: setProject,
    };

    function setProject(project) {
      $root.project = project;
      navbarData.project = project;

      if (prevProjectId === project?.id) {
        return;
      }

      if (project?.id) {
        socketIOService
          .subscribe(Project, {
            id: project.id,
            channels: {
              'assets.healthStatus': true,
            },
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (prevProjectId) {
        socketIOService.unsubscribe(Project, {
          id: prevProjectId,
        });
      }

      prevProjectId = project?.id;
    }

    function setProjects(projects) {
      navbarData.projects = projects;
      $root.projects = projects;
    }
  }
})();
