(function () {
  'use strict';

  const angular = window.angular;
  const $ = window.$;

  Runner.$inject = [
    'LANGS',
    '$transitions',
    '$state',
    '$translate',
    'Manager',
    'LoopBackAuth',
    '$q',
    '$rootScope',
    'socketIOService',
    '$uibModalStack',
  ];

  angular.module('app').run(Runner);

  let firstRun;
  function Runner(
    LANGS,
    $transitions,
    $state,
    $translate,
    Manager,
    LoopBackAuth,
    $q,
    $rootScope,
    socketIOService,
    $uibModalStack
  ) {
    document.addEventListener('colorschemechange', (e) => {
      const isDarkMode = e.detail.colorScheme === 'dark';
      if (isDarkMode === $rootScope.darkMode) {
        return;
      }

      $rootScope.$applyAsync(() => {
        $rootScope.appReady = false;
        if (!firstRun) {
          setTimeout(() => {
            window.location.reload();
          }, 100);

          return;
        }

        $rootScope.appReady = true;
        $rootScope.darkMode = isDarkMode;
      });
    });

    $rootScope.darkMode = getCurrentTheme() === 'dark';
    $rootScope.assetsPath = window.assetsPath;
    $rootScope.langs = LANGS;
    $rootScope.currentLang = $translate.proposedLanguage();
    $rootScope.currentLang = $rootScope.currentLang ? $rootScope.currentLang.trim() : null;

    let operations = 2;
    let opCount = 0;
    $translate.onReady().then(function () {
      opCount++;
      dataTablesDefault($translate);
      if (opCount === operations) {
        $rootScope.appReady = true;
      }
    });

    $transitions.onBefore({}, (transition) => {
      let next = transition.$to().self;
      let defer = $q.defer();

      $rootScope.customerId = Manager.getCachedCurrent()
        ? Manager.getCachedCurrent().customerId
        : null;
      $rootScope.projectId = transition.params().projectId;

      let loginState = $state.target('public.login');
      let member = $state.target('member.home');

      if (next.login) {
        if (Manager.isAuthenticated()) {
          defer.resolve(member);
        } else {
          defer.resolve(true);
        }
      } else if (next.authenticated) {
        if (Manager.isAuthenticated()) {
          defer.resolve(true);
        } else {
          defer.resolve(loginState);
        }
      } else {
        defer.resolve();
      }

      return defer.promise;
    });

    $transitions.onError({}, (transition) => {
      $rootScope.customerId = Manager.getCachedCurrent()
        ? Manager.getCachedCurrent().customerId
        : null;
      $rootScope.projectId = transition.params().projectId;

      let error = transition.error();
      if (error) {
        if (error.type === 5) {
          // The transition was ignored
          $rootScope.viewLoading = false;
        }

        if (error.detail) {
          if (error.detail.redirectTo) {
            $state.go(error.detail.redirectTo, error.detail.params);
          } else if (error.detail.status === 401) {
            $state.go('public.login');
          } else if (error.type !== 2) {
            console.log(error);
            $state.go('member.home');
          }
        }
      }
    });

    $transitions.onStart({}, (transition) => {
      $uibModalStack.dismissAll();
      $rootScope.viewLoading = true;
    });

    $transitions.onFinish({}, (transition) => {
      $uibModalStack.dismissAll();
      $rootScope.customerId = Manager.getCachedCurrent()
        ? Manager.getCachedCurrent().customerId
        : null;
      $rootScope.projectId = transition.params().projectId;

      $rootScope.viewLoading = false;
      opCount++;
      if (opCount === operations) {
        $rootScope.appReady = true;
      }

      let current = transition.targetState()._definition.self;

      if (current.authenticated && !socketIOService.isConnected()) {
        socketIOService.init(window.rtPath, LoopBackAuth.accessTokenId);
      }

      $('#page-wrapper > .absolute').scrollTop(0);
    });

    function dataTablesDefault(translate) {
      translate = translate.instant;

      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          emptyTable: `<div class="alert alert-info no-margin">${translate(
            'datatables.emptyTable'
          )}</div>`,
          zeroRecords: `<div class="alert alert-info no-margin">${translate(
            'datatables.zeroRecords'
          )}</div>`,
          info: translate('datatables.info'),
          infoEmpty: translate('datatables.infoEmpty'),
          infoFiltered: translate('datatables.infoFiltered'),
          lengthMenu: translate('datatables.lengthMenu'),
          loadingRecords: `${translate('datatables.loadingRecords')}`,
          processing: `${translate('datatables.processing')}`,
          search: '',
          searchPlaceholder: translate('datatables.search'),
          paginate: {
            first: translate('datatables.paginate.first'),
            last: translate('datatables.paginate.last'),
            next: translate('datatables.paginate.next'),
            previous: translate('datatables.paginate.previous'),
          },
          aria: {
            sortAscending: translate('datatables.aria.sortAscending'),
            sortDescending: translate('datatables.aria.sortDescending'),
          },
        },
      });
    }

    function getCurrentTheme() {
      const storedTheme = localStorage.getItem('dark-mode-toggle');
      if (storedTheme) {
        return storedTheme;
      }

      const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
      if (userMedia.matches) {
        return 'dark';
      }

      return 'light';
    }
  }
})();
