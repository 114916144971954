(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.project.annualReport', {
      url: '/annual-report?from&to',
      component: 'annualReportMain',
      params: {
        from: { type: 'string', dynamic: true, squash: true },
        to: { type: 'string', dynamic: true, squash: true },
      },
      authenticated: true,
      resolve: {
        dependencies: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + `/js/vendors/chart-vendors-light.min.js`,
              window.assetsPath + '/js/vendors/pdf-vendors.min.js',
              window.assetsPath + '/js/app/dashboard-common.module.min.js',
              window.assetsPath + '/js/app/event-management.module.min.js',
              window.assetsPath + '/js/app/annual-report.module.min.js',
              window.assetsPath + `/css/event-management-light.css`,
              window.assetsPath + '/css/annual-report.css',
            ]);
          },
        ],
        assets: [
          'Customer',
          '$rootScope',
          'AppUtils',
          (Customer, $root) => {
            if ($root.customerId !== '5c8c7820b8bee2001062844d') {
              throw new Error('Unauthorized');
            }
            return Customer.prototype$__get__assets({
              id: $root.customerId,
              filter: {
                where: { projectId: $root.projectId },
                fields: ['id', 'name', 'type', 'customerId', 'assetId'],
              },
            }).$promise;
          },
        ],
      },
      ncyBreadcrumb: {
        label: '<span>{{"entities.tool.__tools.AnnualReport"|translate}}</span>',
      },
    });
  }
})();
