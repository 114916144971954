(function () {
  'use strict';

  const angular = window.angular;
  AuthService.$inject = ['$rootScope', 'AppUtils', 'Manager', '$http'];

  angular.module('app').factory('AuthService', AuthService);

  function AuthService($rootScope, utils, Model, $http) {
    return {
      login: login,
      logout: logout,
      login2fa: login2fa,
    };

    function login2fa(d) {
      let username = d.username;
      let password = d.password;

      let email = '';
      let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (filter.test(username)) {
        email = username;
        username = '';
      }

      let data = {};
      if (username) {
        data.username = username;
      }
      if (email) {
        data.email = email;
      }
      if (password) {
        data.password = password;
      }
      data.token = d.token;
      $rootScope.appReady = false;
      return Model.otpLogin(data)
        .$promise.then((response) => {
          $rootScope.appReady = true;
          return response;
        })
        .catch((err) => {
          $rootScope.appReady = true;
          throw utils.getHTTPError(err);
        });
    }

    function login(username, password, rememberMe) {
      let email = '';
      let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (filter.test(username)) {
        email = username;
        username = '';
      }

      rememberMe = typeof rememberMe === 'boolean' ? rememberMe : false;
      let data = {};
      if (username) {
        data.username = username;
      }
      if (email) {
        data.email = email;
      }
      if (password) {
        data.password = password;
      }

      $rootScope.appReady = false;
      return Model.login({ rememberMe: rememberMe }, data)
        .$promise.then((response) => {
          if (window.environment === 'production' || !window.imageFromProd) {
            return response;
          }
          return $http
            .post('https://api.xompass.com/api/Managers/login', data)
            .then((response2) => {
              const token = response2.data;
              window.localStorage.setItem('$LoopBack$accessTokenId2', token.id);
            })
            .catch((err) => {
              console.log(err);
              return response;
            });
        })
        .then((response) => {
          $rootScope.appReady = true;
          return response;
        })
        .catch((err) => {
          console.log(err);
          $rootScope.appReady = true;
          throw utils.getHTTPError(err);
        });
    }

    function logout() {
      return Model.logout()
        .$promise.then(function (response) {
          LoopBackAuth.clearUser();
          LoopBackAuth.clearStorage();
          return response;
        })
        .catch(function (err) {
          LoopBackAuth.clearUser();
          LoopBackAuth.clearStorage();
          throw utils.getHTTPError(err);
        });
    }
  }
})();
