;(function () {
  'use strict';

  const angular = window.angular;

  OrganizationController.$inject = ['$uibModal', '$state', 'LoopBackAuth', 'AppUtils'];

  angular
    .module('app')
    .component('accountOrganization', {
      template:'<div class="well well-light"><div class="profile-content"><div class="profile-left"><div class="profile-left-fix"><div class="text-center thumbnail marginB10"><img ng-if="ctrl.organization.image" ng-src="{{ctrl.organization.image}}"> <img ng-if="!ctrl.organization.image" ng-src="{{$root.assetsPath}}/images/no-image.png"></div></div></div><div class="profile-right"><div class="table-responsive"><table class="table table-borderless no-margin"><thead><tr><th colspan="2"><div class="pull-left"><h2 class="no-margin text-info"><span>{{ctrl.organization.name}}</span></h2><h3 class="no-margin" ng-if="ctrl.organization.businessName">{{ctrl.organization.businessName}}</h3></div></th></tr></thead><tbody><tr class="separator"><td colspan="2"></td></tr><tr class="bordered"><td class="field" translate>entities._all.address</td><td>{{ctrl.organization.address}}</td></tr><tr class="bordered"><td class="field" translate>entities._all.country</td><td>{{ctrl.organization.country.name}}</td></tr><tr class="bordered"><td class="field" translate>entities._all.description</td><td><p>{{ctrl.organization.description}}</p></td></tr></tbody></table></div></div></div></div><div class="panel panel-default"><header class="panel-heading"><h2 class="nowrap"><strong translate>entities.organization.members</strong></h2><div class="panel-toolbars pull-right" ng-if="ctrl.user.permission.master"><div class="panel-toolbar"><button class="btn btn-primary" ng-click="ctrl.showCreateUserModal()"><i class="fas fa-plus"></i></button></div></div></header><div class="panel-body no-padding"><users-table users="ctrl.users" edit="ctrl.user.permission.master" remove="ctrl.user.permission.master" time-zones="ctrl.timeZones" countries="ctrl.countries"></users-table></div></div>',
      controller: OrganizationController,
      controllerAs: 'ctrl',
      bindings: {
        user: '<',
        industries: '<',
        countries: '<',
        timeZones: '<',
        organization: '<'
      }
    });

  function OrganizationController ($uibModal, $state, LoopBackAuth, utils) {
    let vm = this;

    vm.$onInit = function () {
      vm.image = {};
      vm.showImageModal = showImageModal;

      if (vm.organization.container && vm.organization.container._logo) {
        let file = vm.organization.container._logo;
        vm.organization.image = utils.getEntityFileUrl('Customer', vm.organization.id, 'logo', file, LoopBackAuth.accessTokenId);
      }

      if (vm.organization.countryId) {
        vm.organization.country = vm.countries.find(country => vm.organization.countryId === country.id);
      }

      vm.users = vm.organization.managers.filter(current => current.id !== vm.user.id);

      vm.showEditModal = showEditModal;
      vm.showCreateUserModal = showCreateUserModal;
    };

    function showEditModal () {
      let instance = $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'accountOrganizationEdit',
          resolve: {
            organization: function () {
              return vm.organization;
            },
            countries: function () {
              return vm.countries;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showImageModal () {
      let instance = $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'accountProfileImage',
          resolve: {
            entityId: vm.organization.id,
            entity: function () {
              return 'Customer';
            },
            property: function () {
              return 'logo';
            }
          }
        });

      instance
        .result
        .then(function (result) {
          if (result.files && result.files.file && result.files.file.length) {
            let file = result.files.file[0];
            vm.organization.image = utils.getEntityFileUrl('Customer', vm.organization.id, 'logo', file, LoopBackAuth.accessTokenId);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showCreateUserModal () {
      let instance = $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'managerForm',
          size: 'lg',
          resolve: {
            customerId: function () {
              return vm.organization.id;
            },
            user: function () {
              return {};
            },
            countries: function () {
              return vm.countries;
            },
            timezones: function () {
              return vm.timeZones;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();

