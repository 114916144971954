(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.project.telegramEvents', {
      url: '/telegram/:groupId?',
      component: 'telegram',
      params: {
        groupId: {
          type: 'string',
          dynamic: true,
          value: undefined,
        },
      },
      authenticated: true,
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([window.assetsPath + '/js/app/telegram.module.min.js']);
          },
        ],
      },
      ncyBreadcrumb: {
        label: '<span>{{"entities.tool.__tools.TelegramEvents"|translate}}</span>',
      },
    });
  }
})();
