;(function () {
  'use strict'

  const angular = window.angular

  HomeController.$inject = ['$uibModal', '$state']

  angular
    .module('app')
    .component('customerProjects', {
      template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-home fa-fw"></i> <span class="header-text"><span translate>general.welcome</span> {{$ctrl.user.name}}</span></h1></div><div class="container"><h2 class="text-info marginT0" translate>entities.project.modelNamePl</h2><hr class="marginT0"><div class="home-shortcuts"><a class="shortcut" ng-repeat="project in $ctrl.projects" ui-sref="member.project.home({projectId: project.id})"><div class="shortcut-content"><div class="link-icon"><i class="fas fa-folder"></i></div><div class="link-text-wrapper"><div class="link-text">{{project.name}}</div></div></div></a></div></div>',
      controller: HomeController,
      bindings: {
        projects: '<',
        user: '<',
        organization: '<'
      }
    })

  function HomeController ($uibModal, $state) {
    let vm = this

    vm.$onInit = function () {
      vm.showProjectForm = showProjectForm
    }

    function showProjectForm (project) {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'projectForm',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            customer: function () {
              return vm.organization
            },
            project: function () {
              return project
            }
          }
        })

      instance
        .result
        .then(function (result) {
          if (!result) {
            return
          }

          $state.reload()
        })
        .catch(function (err) {
          console.log(err)
        })
    }
  }

})()
