(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider
      .state('member.project.storyline', {
        url: '/storylines',
        template: '<ui-view></ui-view>',
        redirectTo: 'member.project.storyline.list',
        authenticated: true,
        resolve: {
          module: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/storyline.module.min.js']);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"entities.tool.__tools.Storyline" | translate}}',
        },
      })
      .state('member.project.storyline.list', {
        url: '/',
        component: 'storyline',
        authenticated: true,
        resolve: {
          module: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/storyline.module.min.js']);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"entities.tool.__tools.Storyline" | translate}}',
        },
      })
      .state('member.project.storyline.show', {
        url: '/:storylineId',
        component: 'storyline',
        authenticated: true,
        params: {
          storylineId: {
            type: 'string',
          },
        },
        ncyBreadcrumb: {
          label: '{{"entities.tool.__tools.Storyline" | translate}}',
        },
      });
  }
})();
