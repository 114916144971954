(function () {
  'use strict';

  let angular = window.angular;

  ProjectRouter.$inject = ['$stateProvider'];

  angular.module('app').config(ProjectRouter);

  function ProjectRouter($stateProvider) {
    $stateProvider
      .state('member.project', {
        url: '/project/:projectId',
        authenticated: true,
        template: '<ui-view></ui-view>',
        redirectTo: 'member.project.home',
        projectRoot: true,
        resolve: {
          project: [
            'RouterService',
            'Manager',
            '$transition$',
            'ToolService',
            'navbarService',
            'AppUtils',
            '$translate',
            function (
              RouterService,
              Manager,
              $transition$,
              ToolService,
              navbarService,
              utils,
              $translate
            ) {
              let params = $transition$.params();
              let user = Manager.getCachedCurrent();
              RouterService.storeId(params.projectId, 'Customer$CurrentProject');
              return RouterService.getProject({
                customerId: user.customerId,
                projectId: params.projectId,
              })
                .then((project) => {
                  const modules = ToolService.getToolsModules();
                  project.links = project.tools.reduce((links, tool) => {
                    const module = modules[tool.module];
                    if (module) {
                      module.name = tool.name;
                      module.langCode = tool.module.replace(/xompass|vsaas/, '');
                      const label = module.langCode
                        ? $translate.instant('entities.tool.__tools.' + module.langCode)
                        : module.name;
                      module.label = label;
                      module.inlineLabel = label.replace(/<[^>]+>/gm, '');
                      links.push(module);
                    }
                    return links;
                  }, []);
                  project.links.sort((prev, current) => {
                    return prev.name > current.name ? 1 : -1;
                  });
                  navbarService.setProject(project);
                  return project;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.home',
                    params: { customerId: user.customerId },
                  });
                });
            },
          ],
          assets: [
            'assetUtilsService',
            '$rootScope',
            function (assetUtilsService, $rootScope) {
              return assetUtilsService.loadTree().then((tree) => {
                subscribeToHealthStatus($rootScope, tree);
                return tree;
              });
            },
          ],
          tools: [
            'project',
            (project) => {
              const tools = [];
              Array.prototype.push.apply(tools, project.tools);
              Array.prototype.push.apply(tools, project.adminTools);
              return tools;
            },
          ],
          assetTypes: [
            'RouterService',
            '$transition$',
            '$rootScope',
            'project',
            function (RouterService, $transition$, $root, project) {
              let params = $transition$.params();
              return RouterService.getProjectAssetTypes({
                customerId: $root.customerId,
                projectId: params.projectId,
              }).then((assetTypes) => {
                $root.assetTypes = assetTypes;
                $root.sensorTypes = assetTypes.reduce((array, at) => {
                  for (let st of at.sensorTypes) {
                    if (array.findIndex((c) => c.type === st.type) === -1) {
                      array.push(st);
                    }
                  }
                  return array;
                }, []);
                if (project.allowedSensorTypes && project.allowedSensorTypes.length) {
                  for (let assetType of assetTypes) {
                    assetType.sensorTypes = assetType.sensorTypes.filter((sensorType) => {
                      return project.allowedSensorTypes.find((c) => c.id === sensorType.id);
                    });
                  }
                }
                return assetTypes;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.project.name}}',
        },
      })

      .state('member.project.home', {
        url: '/',
        redirectTo: 'member.project.home.directAccess',
        component: 'projectHome',
        ncyBreadcrumb: { skip: true },
      })

      .state('member.project.home.directAccess', {
        url: 'direct-access',
        authenticated: true,
        component: 'projectLinks',
        ncyBreadcrumb: { label: '{{"entities.tool.modelNamePl" | translate}}' },
      })

      .state('member.project.home.profile', {
        url: 'profile',
        authenticated: true,
        component: 'projectProfile',
        ncyBreadcrumb: { label: '{{"general.configuration" | translate}}' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      });

    function subscribeToHealthStatus($rootScope, assets) {
      const leafs = assets.tree.reduce((map, leaf) => {
        map[leaf.id] = leaf;
        return map;
      }, {});

      $rootScope.$on('Asset.healthStatus', (event, data) => {
        const instanceId = data.through?.id;
        const status = data.body?.currentHealthStatus;
        if (!assets.assets[instanceId]) {
          return;
        }

        assets.assets[instanceId].currentHealthStatus = status;
        leafs[instanceId].li_attr['data-status'] = status;
      });
    }
  }
})();
