;(function () {
  'use strict';

  let angular = window.angular;

  AssetService.$inject = ['Customer', '$q', '$rootScope', '$state', 'assetUtilsService', 'AppUtils'];

  angular
    .module('appServices')
    .service('AssetService', AssetService);

  function AssetService (Customer, $q, $root, $state, assetUtilsService, utils) {
    return {
      findById: findById,
      find: find,
      loadSummaries: loadSummaries,
      loadAlerts: loadAlerts,
      loadEvents: loadEvents,
      getAssetTree: assetUtilsService.getAssetTree,
      getAssetTreeNode: assetUtilsService.getAssetTreeNode,
      reloadImageUrl: reloadImageUrl,
    };

    function loadSummaries (assetId, sensorIds, from, to) {
      return assetUtilsService.loadSummaries($root.customerId, assetId, sensorIds, from, to);
    }

    function loadAlerts (assetId, filters, options) {
      return assetUtilsService.loadAlerts($root.customerId, assetId, filters, options);
    }

    function loadEvents (assetId, filters, options) {
      return assetUtilsService.loadEvents($root.customerId, assetId, filters, options);
    }

    function findById (assetId, filter) {
      filter = filter || {};
      filter.where = {id: assetId};

      let defer = $q.defer();
      Customer
        .assets({
          id: $root.customerId,
          filter: filter,
        })
        .$promise
        .then(assets => {
          const asset = assets[0];
          if (!asset) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            return defer.reject(err);
          }

          if (asset.assets) {
            asset.assets.sort((a, b) => utils.sortComparator(a.name, b.name));
          }

          defer.resolve(asset);
        })
        .catch(err => {
          console.log(err);
          defer.reject(err);
        });

      return defer.promise;
    }

    function find (filter) {
      filter = filter || {};

      let params = $state.params;
      let defer = $q.defer();
      Customer
        .prototype$__get__assets({
          id: $root.customerId,
          filter: filter,
        })
        .$promise
        .then(assets => {
          defer.resolve(assets);
        })
        .catch(err => {
          console.log(err);
          defer.reject(err);
        });

      return defer.promise;
    }

    function reloadImageUrl (asset, url) {
      const urlParams = new URLSearchParams(url);
      const property = urlParams.get('property');
      return findById(asset.id, {include: 'container', fields: ['id']})
        .then(result => {
          asset.container = result.container;
          return assetUtilsService.getImageUrl(asset, property);
        });
    }
  }
})();
